import {
  DiscordIcon, MediumIcon, TelegramIcon, TwitterIcon,
} from 'theme/icons';

export const PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;

export const isMainnet = true;

export const adminRoleBytes = '0x0000000000000000000000000000000000000000000000000000000000000000';

export const backendUrl = isMainnet ? 'https://seed-box.io/api/v1/' : 'https://microtuber.rocknblock.io/api/v1/';

export const binanceChainConfig = [{
  chainId: isMainnet ? '0x38' : '0x61',
  chainName: isMainnet ? 'BSC' : 'BSC TEST',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: isMainnet ? ['https://bsc-rpc.publicnode.com'] : ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
  blockExplorerUrls: isMainnet ? ['https://bscscan.com'] : ['https://testnet.bscscan.com/'],
}];

export const outerPages = [
  {
    name: 'Microtuber',
    link: 'https://microtuber.io/',
  },
  {
    name: 'Staking Roadmap',
    link: 'https://microtuber.medium.com/seed-box-staking-roadmap-3d9a043e1db6',
  },
  // {
  //   name: 'Past Pools',
  //   link: 'https://v2seedbox.com/',
  // },
];
export const socialLinks = [
  {
    name: 'twitter',
    link: 'https://twitter.com/micro_tuber/',
    SocialIcon: TwitterIcon,
  },
  {
    name: 'telegram',
    link: 'https://t.me/metarising',
    SocialIcon: TelegramIcon,
  },
  {
    name: 'discord',
    link: 'https://discord.gg/metarising',
    SocialIcon: DiscordIcon,
  },
  {
    name: 'medium',
    link: 'https://microtuber.medium.com/',
    SocialIcon: MediumIcon,
  },
];

export const DELAY_BEFORE_UPDATE = 5500;
