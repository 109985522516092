import axios, { AxiosRequestConfig } from 'axios';

import { backendUrl } from 'config/constants';

axios.defaults.baseURL = backendUrl;

axios.interceptors.request.use(
  (config:AxiosRequestConfig) => {
    const token = localStorage.getItem('microtuber_token');
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.common = {
        // @ts-ignore
        ...config.headers.common,
        Authorization: token,
      };
    }
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);

export default axios;
